import logo from './logo.svg';
import './App.css';
import { Link, Route, Routes } from 'react-router-dom';
import { Login } from './pages/Login';
import { BrowserRouter } from 'react-router-dom';
import { Register } from './pages/Register';
import { Dashboard } from './pages/Dashboard';
import { PetView } from './pages/PetView';
import PetdotsLogo from './resources/petdot_nobg_backshadow.png';
import { DashboardVet } from './pages/Dashboard_vet';
import { PetViewVet } from './pages/PetViewVet';
import { DetailView } from './pages/DetailView';
import { NewVisit } from './pages/NewVisit';
import { EditNotes } from './pages/EditNotes';

function App() {
  return (
    <><div style = {{height: "100%", fontFamily: "outfit"}}><nav>

        <div style = {{fontFamily: "Outfit", fontSize: "1.2em", height: 40, backgroundColor: "#191919", display: "flex", alignItems: "center"}}><pre> </pre><Link style = {{maxHeight: 30, minWidth: 200, backgroundColor: "#191919", display: "flex", alignItems: "center", textDecoration: "none", color: "#F3F3F3"}} to="/"><img style = {{maxHeight: 30}} src={PetdotsLogo} alt="Pet Dot"></img> <pre> </pre> <b>Pet Dot</b></Link></div>

    </nav><Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register/" element={<Register />} />
        <Route path="/dashboard/" element={<Dashboard />} />
        <Route path="/petview/" element={<PetView />} />
        <Route path="/dashboard-vet/" element={<DashboardVet />} />
        <Route path="/petview-vet/" element={<PetViewVet />} />
        <Route path="/detail/" element={<DetailView />} />
        <Route path="/new-visit/" element={<NewVisit />} />
        <Route path="/edit-notes/" element={<EditNotes />} />

      </Routes></div></>
  );
}

export default App;

/*        <Link to="/">Login</Link>
<br></br>
<Link to="/register/">Register</Link>
*/