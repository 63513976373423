import { Link } from "react-router-dom";
import { useState } from "react";
import petDotCircleLogo from './../resources/petdot_circle_withtxt.png';
import "./../App.css";

export function Register() {

    const [state, setState] = useState("/dashboard-vet/");

    return(
            <div style = {{minHeight: "100%", width: "100%",backgroundColor: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style = {{backgroundColor: "#FFFFFF", textAlign: "left", padding: "50px", borderRadius: "1em", borderCollapse: "separate", maxWidth: 400}}>
            <div style = {{width: "100%", textAlign: "center"}}><img style = {{maxHeight: 300}} src={petDotCircleLogo} alt="Pet Dot"></img></div>
                <p1 style = {{fontSize: "2em"}}>Sign Up</p1><br></br>
                <p1 style = {{textAlign: "left", display: "inline-block", fontFamily: "Assistant", fontSize: "1.2em"}}>Let's get you connected with your furry friend!</p1>
                <form style={{textAlign: "left", display: "inline-block"}}>
                    <label for="email" style = {{fontSize: "1.5em"}}><br></br>Email</label><br/>
                   <input type="text" id="email" name="email" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/>
                   <label for="phone" style = {{fontSize: "1.5em"}}><br></br>Phone Number</label><br/>
                   <input type="text" id="phone" name="phone" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/><br/>
                   <label for="passwd" style = {{fontSize: "1.5em"}}>Password</label><br/>
                    <input type="password" id="passwd" name="passwd" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/><br/>
                    <label for="passwd" style = {{fontSize: "1.5em"}}>Confirm Password</label><br/>
                    <input type="password" id="passwd" name="passwd" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/><br/>
                    <label for="usertype" style = {{fontSize: "1.5em"}}>Which type of user are you?</label><br/>
                    <label style = {{fontFamily: "Assistant"}}
                    value={state}
        onChange={(event) => {
            if (state == "/dashboard/") {setState("/dashboard-vet/");}
            else {setState("/dashboard/");}
        }}><input type="checkbox" id="owner" name="owner" />Pet Owner<br/></label>
                    <label style = {{fontFamily: "Assistant"}}><input type="checkbox" id="vet" name="vet"/>Veternarian</label>
                </form>
                <br/><br/>
                <Link to = {state}>
                <div style={{minHeight: "30px", backgroundColor: "#191919", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "#F3F3F3", fontSize: "2em", padding: ".25em", borderRadius: ".25em"}}><b>Sign Up</b></div></Link>
                <div style = {{width: "100%", textAlign: "center"}}><p style={{textAlign: "left", display: "inline-block", fontFamily: "Assistant"}}>Already have a PetDot Account? Log in <Link to = "/" style={{color: "black"}}><b>HERE</b></Link></p></div>
                <br></br>
        </div>

        </div>
    );
}