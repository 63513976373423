import { Link } from "react-router-dom";

export function PetViewVet() {
    return(
        <div style = {{minHeight: "100%", width: "100%",backgroundColor: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div>
            <div style = {{minHeight: "100%", width: "100%",backgroundColor: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{textAlign: "center"}}><h1>BunBun</h1><h2 style={{fontFamily: "assistant"}}>2/21/2024, 4:42 AM EST</h2>
            <br/>
            <h3 style={{textAlign: "left"}}><u>Visit Info:</u></h3>
            <div style = {{margin: "1em", backgroundColor: "#FFFFFF", textAlign: "left", padding: "1em", borderRadius: "1em", borderCollapse: "separate", maxWidth: 450}}>
                <b>Checked-In:</b>
                <p style={{fontFamily: "assistant"}}>2/21/2024, 4:42 AM EST</p> 
                <b>Checked-Out:</b>
                <p style={{fontFamily: "assistant"}}>2/21/2024, 6:42 AM EST</p> 
            </div>
            <h3 style={{textAlign: "left"}}><u>Pet Notes:</u></h3>
            <div style = {{margin: "1em", backgroundColor: "#FFFFFF", textAlign: "left", padding: "1em", borderRadius: "1em", borderCollapse: "separate", maxWidth: 450}}>
                <p style={{fontFamily: "assistant"}}>Pet has scars on its upper thigh</p> 
            </div>
            <Link to = "/edit-notes/">
                    <div style={{margin: "1em", backgroundColor: "#191919", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "white", padding: "1em"}}><b>Edit Notes</b></div>
                </Link>
                    <div style={{margin: "1em", backgroundColor: "#7d7d7d", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "white", padding: "1em"}}><b>checked-out</b></div>
                </div>


                <div style = {{margin: "1em", textAlign: "center", padding: "50px", borderRadius: "1em", borderCollapse: "separate", width: "40em"}}>
                <h2 style={{textAlign: "left"}}>Pet Vitals</h2>
                <div style={{margin: "1em", backgroundColor: "#FFFFFF", textAlign: "center", padding: "50px", borderRadius: "1em", borderCollapse: "separate", width: "40em"}}>
                <Link to = "/dashboard/">
                <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "inline-block", textAlign: "center" , textDecoration: "none", color: "#000000", padding: "1em", width: "15%", height: "5em"}}><b style={{fontSize: ".75em", color: "black"}}>Avg. Heart Rate:</b><br/><p style={{fontSize: "1em", color: "darkgreen"}}><b>73.2</b></p></div>
                </Link>
                <Link to = "/dashboard/">
                    <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "inline-block", textAlign: "center" , textDecoration: "none", color: "#000000", padding: "1em", width: "15%", height: "5em"}}><b style={{fontSize: ".75em", color: "black"}}>Avg. Resp. Rate:</b><br/><p style={{fontSize: "1em", color: "darkgreen"}}><b>13.5</b></p></div>
                </Link>

                <Link to = "/dashboard/">
                <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "inline-block", textAlign: "center" , textDecoration: "none", color: "#000000", padding: "1em", width: "15%", height: "5em"}}><b style={{fontSize: ".75em", color: "black"}}>Avg. Systolic:</b><br/><p style={{fontSize: "1em", color: "darkgreen"}}><b>125</b></p></div>
                </Link>
                <Link to = "/dashboard/">
                <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "inline-block", textAlign: "center" , textDecoration: "none", color: "#000000", padding: "1em", width: "15%", height: "5em"}}><b style={{fontSize: ".75em", color: "black"}}>Avg. Diastolic:</b><br/><p style={{fontSize: "1em", color: "darkgreen"}}><b>75.2</b></p></div>
                </Link>
                </div>

                <h2 style={{textAlign: "left"}}>Owner Information</h2>
                <div style={{margin: "1em", backgroundColor: "#FFFFFF", textAlign: "center", padding: "50px", borderRadius: "1em", borderCollapse: "separate", width: "40em"}}>
                <b>Owner Name:</b>
                <p style={{fontFamily: "assistant"}}>Helium Yang</p> 
                <b>Owner Email:</b>
                <p style={{fontFamily: "assistant"}}>helium.yang@heliumyang.com</p> 
                <b>Owner Phone:</b>
                <p style={{fontFamily: "assistant"}}>(123)456-7890</p> 
                </div>

                </div>
            </div>
            </div>
        </div>
    );
}