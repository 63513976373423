import { Link } from "react-router-dom";
import petDotCircleLogo from './../resources/petdot_circle_withtxt.png';
import "./../App.css";
import { useState } from "react";

export function Login() {

    const [state, setState] = useState("/dashboard/");

    return(
        <div style = {{minHeight: "100%", width: "100%",backgroundColor: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style = {{backgroundColor: "#FFFFFF", textAlign: "left", padding: "50px", borderRadius: "1em", borderCollapse: "separate", maxWidth: 400}}>
            <div style = {{width: "100%", textAlign: "center"}}><img style = {{maxHeight: 300}} src={petDotCircleLogo} alt="Pet Dot"></img></div>
                <p1 style = {{fontSize: "2em"}}>Log In</p1><br></br>
                <p1 style = {{textAlign: "left", display: "inline-block", fontFamily: "Assistant", fontSize: "1.2em"}}>Let's get you connected with your furry friend!</p1>
                <form style={{textAlign: "left", display: "inline-block"}}>
                    <label for="email" style = {{fontSize: "1.5em"}}><br></br>Email</label><br/>
                   <input type="text" id="email" name="email" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}} onChange={(event) => {
                    if (event.target.value.includes("vet")) {
                        setState("/dashboard-vet/");
                    } else {
                        setState("/dashboard/");
                    }
                   }}/><br/><br/>
                   <label for="passwd" style = {{fontSize: "1.5em"}}>Password</label><br/>
                    <input type="password" id="passwd" name="passwd" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/><br/><br/>
                    <label style = {{fontFamily: "Assistant"}}><input type="checkbox" id="remember" name="remember"/>Stay signed in</label>
                </form>
                <br/><br/>
                <Link to = {state}>
                    <div style={{minHeight: "30px", backgroundColor: "#191919", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "#F3F3F3", fontSize: "2em", padding: ".25em", borderRadius: ".25em"}}><b>Log In</b></div>
                </Link>
                <div style = {{width: "100%", textAlign: "center"}}><p style={{textAlign: "left", display: "inline-block", fontFamily: "Assistant"}}>Don’t have a PetDot Account? Sign up <Link to = "/register/" style={{color: "black"}}><b>HERE</b></Link></p></div>
                <br></br>
            </div>
        </div>
    );
}