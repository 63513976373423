import { Link } from "react-router-dom";
import petDotCircleLogo from './../resources/petdot_circle_withtxt.png';
import "./../App.css";
import { useState } from "react";

export function NewVisit() {

    return(
        <div style = {{minHeight: "100%", width: "100%",backgroundColor: "#F3F3F3", alignItems: "center", justifyContent: "center"}}>
            <br/><h1 style={{paddingLeft: "1em"}}>Add a New Visit</h1>
            <div style = {{backgroundColor: "#FFFFFF", textAlign: "left", padding: "50px", borderRadius: "1em", borderCollapse: "separate"}}>
                <form style={{textAlign: "left", display: "inline-block"}}>
                    <label for="email" style = {{fontSize: "1.5em"}}><br></br>Device ID: </label><br/>
                   <input type="text" id="email" name="email" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/><br/>
                   <label for="phone" style = {{fontSize: "1.5em"}}><br></br>Owner Name: </label><br/>
                   <input type="text" id="phone" name="phone" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/><br/><br/>
                   <label for="passwd" style = {{fontSize: "1.5em"}}>Owner Email: </label><br/>
                    <input type="text" id="passwd" name="passwd" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/><br/><br/>
                    <label for="passwd" style = {{fontSize: "1.5em"}}>Owner Phone Number: </label><br/>
                    <input type="text" id="passwd" name="passwd" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/><br/>
                    <label for="phone" style = {{fontSize: "1.5em"}}><br></br>Pet Name: </label><br/>
                   <input type="text" id="phone" name="phone" style = {{fontSize: "1.5em", backgroundColor: "#F3F3F3", borderRadius: "5px", width: "400px"}}/><br/>
                </form>
                <br/><br/>
                <Link to = "/petview-vet/">
                <div style={{minHeight: "30px", backgroundColor: "#191919", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "#F3F3F3", fontSize: "2em", padding: ".25em", borderRadius: ".25em"}}><b>Add Visit</b></div></Link>
                <br></br>
                <Link to = "/dashboard-vet/">
                    <div style={{minHeight: "30px", backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "black", fontSize: "2em", padding: ".25em", borderRadius: ".25em", borderColor: "black"}}><b>Cancel</b></div></Link>
        </div>

        </div>
    );
}