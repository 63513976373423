import { Link } from "react-router-dom";

export function Dashboard() {
    return(
<div style = {{minHeight: "100%", width: "100%",backgroundColor: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div>
            <h1>Welcome Helium!</h1>
            <div style = {{minHeight: "100%", width: "100%",backgroundColor: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center", height: "80%"}}>
            <div style = {{margin: "1em", backgroundColor: "#FFFFFF", textAlign: "center", padding: "2em", borderRadius: "1em", borderCollapse: "separate", maxWidth: 450, height: "40em"}}>
                <h2>Visits</h2>
                <Link to = "/petview/">
                    <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "#000000", padding: "1em"}}><b>BunBun - February 21st, 2024 at 4:42 AM EST</b></div>
                </Link>
                <Link to = "/dashboard/">
                    <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "#000000", padding: "1em"}}><b>BunBun - February 20th, 2024 at 5:32 PM EST</b></div>
                </Link>
                <Link to = "/dashboard/">
                    <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "flex", alignItems: "center", justifyContent: "center", textDecoration: "none", color: "#000000", padding: "1em"}}><b>BunBun - February 15th, 2024 at 6:24 AM EST</b></div>
                </Link>
                </div>


                <div style = {{margin: "1em", backgroundColor: "#FFFFFF", textAlign: "center", padding: "50px", borderRadius: "1em", borderCollapse: "separate", maxWidth: 400, height: "40em"}}>
                <h2>Notifications</h2>
                <Link to = "/dashboard/">
                <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "inline-block", textAlign: "left" , textDecoration: "none", color: "#000000", padding: "1em", width: "80%"}}><b style={{color: "black"}}>VITALS - Vitals are back to normal for BunBun</b><br/><p style={{fontSize: ".75em"}}>1 month ago</p></div>
                </Link>
                <Link to = "/dashboard/">
                    <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "inline-block", textAlign: "left" , textDecoration: "none", color: "#000000", padding: "1em", width: "80%"}}><b style={{color: "red"}}>ALERT - Vital critical for BunBun</b><br/><p style={{fontSize: ".75em"}}>1 month ago</p></div>
                </Link>
                <Link to = "/dashboard/">
                <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "inline-block", textAlign: "left" , textDecoration: "none", color: "#000000", padding: "1em", width: "80%"}}><b style={{color: "black"}}>MESSAGE - Dr. Claire Hensley added notes for BunBun</b><br/><p style={{fontSize: ".75em"}}>1 month ago</p></div>
                </Link>
                <Link to = "/dashboard/">
                <div style={{margin: "1em", backgroundColor: "#ffffff", borderColor: "#000000", borderStyle: "solid", borderWidth: "2px", borderRadius: "1em", display: "inline-block", textAlign: "left" , textDecoration: "none", color: "#000000", padding: "1em", width: "80%"}}><b style={{color: "black"}}>MESSAGE - Dr. Molly Hyser checked in BunBun to VET</b><br/><p style={{fontSize: ".75em"}}>1 month ago</p></div>
                </Link>
                </div>
            </div>
            </div>
        </div>
    );
}